
$(document).on('keyup', '.list-client-search', function (e) {
    var searchkey = $('.list-client-search').val();
    console.log(searchkey);
    if (searchkey.length > 0) {
        $('.internal-list .search-box span').removeClass('fa-search');
        $('.internal-list .search-box span').addClass('fa-close');
    } else {
        $('.internal-list .search-box span').removeClass('fa-close');
        $('.internal-list .search-box span').addClass('fa-search');
    }
});
$(document).on('keyup', '#client-search', function (e) {
    var searchkey = $('#client-search').val();
    console.log(searchkey);
    if (searchkey.length > 0) {
        $('.search-section .search-box span').removeClass('fa-search');
        $('.search-section .search-box span').addClass('fa-close');
    } else {
        $('.search-section .search-box span').removeClass('fa-close');
        $('.search-section .search-box span').addClass('fa-search');
    }
});
$(document).on('keyup', '#other-search', function (e) {
    var searchkey = $('#other-search').val();
    console.log(searchkey);
    if (searchkey.length > 0) {
        $('.search-section .search-box span').removeClass('fa-search');
        $('.search-section .search-box span').addClass('fa-close');
    } else {
        $('.search-section .search-box span').removeClass('fa-close');
        $('.search-section .search-box span').addClass('fa-search');
    }
});
$(document).on('keyup', '#assign-search', function (e) {
    var searchkey = $('#assign-search').val();
    console.log(searchkey);
    if (searchkey.length > 0) {
        $('#assign-any .search-box span').removeClass('fa-search');
        $('#assign-any .search-box span').addClass('fa-close');
    } else {
        $('#assign-any .search-box span').removeClass('fa-close');
        $('#assign-any .search-box span').addClass('fa-search');
    }
});

$(document).on('keyup', '#share-search', function (e) {
    var searchkey = $('#share-search').val();
    console.log(searchkey);
    if (searchkey.length > 0) {
        $('#share-client .search-box span').removeClass('fa-search');
        $('#share-client .search-box span').addClass('fa-close');
    } else {
        $('#share-client .search-box span').removeClass('fa-close');
        $('#share-client .search-box span').addClass('fa-search');
    }
});

$(document).on('keyup', '#note-share-search', function (e) {
    var searchkey = $('#note-share-search').val();
    console.log(searchkey);
    if (searchkey.length > 0) {
        $('#add-shares .search-box span').removeClass('fa-search');
        $('#add-shares .search-box span').addClass('fa-close');
    } else {
        $('#add-shares .search-box span').removeClass('fa-close');
        $('#add-shares .search-box span').addClass('fa-search');
    }
});

$(document).on('click', '.search-section .search-box .fa-close', function () {
    $('#client-search').val('');
    $('#other-search').val('');
    $('.user-list .list-group-item').removeClass('d-none');
    $('.search-section .search-box span').removeClass('fa-close');
    $('.search-section .search-box span').addClass('fa-search');
});

$(document).on('click', '.internal-list .search-box .fa-close', function () {
    $('.list-client-search').val('');
    $('.internal-list .list-group-item').removeClass('d-none');
    $('.internal-list .search-box span').removeClass('fa-close');
    $('.internal-list .search-box span').addClass('fa-search');
});

$(document).on('click', '#assign-any .search-box .fa-close', function () {
    $('#assign-search').val('');
    $('.assign-list .list-group-item').removeClass('d-none');
    $('#assign-any .search-box span').removeClass('fa-close');
    $('#assign-any .search-box span').addClass('fa-search');
});

$(document).on('click', '#share-client .search-box .fa-close', function () {
    $('#share-search').val('');
    $('.share-list .list-group-item').removeClass('d-none');
    $('#share-client .search-box span').removeClass('fa-close');
    $('#share-client .search-box span').addClass('fa-search');
});

$(document).on('click', '#add-shares .search-box .fa-close', function () {
    $('#share-search').val('');
    $('.note-share-list .list-group-item').removeClass('d-none');
    $('#add-shares .search-box span').removeClass('fa-close');
    $('#add-shares .search-box span').addClass('fa-search');
});

$(document).on('keyup', '.list-client-search', function (e) {
    var search = $('.list-client-search').val();
    var keyword = search.toLowerCase();
    $('.internal-list .list-group-item').each(function () {
        var item = $(this).attr('data-name');
        if (item) {
            if (item.toLowerCase().indexOf(keyword) == -1) {
                $(this).addClass('d-none');
            } else {
                $(this).removeClass('d-none');
            }
        }
    });
});

$(document).on('keyup', '#client-search', function (e) {
    var search = $('#client-search').val();
    var keyword = search.toLowerCase();
    $('.user-list .list-group-item').each(function () {
        var item = $(this).attr('data-name');
        var patientid = $(this).attr('data-patientid');
        console.log(patientid);
        if (item) {
            if ((item.toLowerCase().indexOf(keyword) == -1) && (patientid.toLowerCase().indexOf(keyword) == -1)) {
                $(this).addClass('d-none');
            } else {
                $(this).removeClass('d-none');
            }
        }
    });
});

$(document).on('keyup', '#other-search', function (e) {
    var search = $('#other-search').val();
    var keyword = search.toLowerCase();
    $('.user-list .list-group-item').each(function () {
        var item = $(this).attr('data-name');
        console.log(patientid);
        if (item) {
            if (item.toLowerCase().indexOf(keyword) == -1) {
                $(this).addClass('d-none');
            } else {
                $(this).removeClass('d-none');
            }
        }
    });
});

$(document).on('keyup', '#assign-search', function (e) {
    var search = $('#assign-search').val();
    var keyword = search.toLowerCase();
    console.log(keyword);
    $('.assign-list .list-group-item').each(function () {
        var item = $(this).attr('data-name');
        if (item) {
            if (item.toLowerCase().indexOf(keyword) == -1) {
                $(this).addClass('d-none');
            } else {
                $(this).removeClass('d-none');
            }
        }
    });
});

$(document).on('keyup', '#share-search', function (e) {
    var search = $('#share-search').val();
    var keyword = search.toLowerCase();
    console.log(keyword);
    $('.share-list .list-group-item').each(function () {
        var item = $(this).attr('data-name');
        if (item) {
            if (item.toLowerCase().indexOf(keyword) == -1) {
                $(this).addClass('d-none');
            } else {
                $(this).removeClass('d-none');
            }
        }
    });
});

$(document).on('keyup', '#note-share-search', function (e) {
    var search = $('#note-share-search').val();
    var keyword = search.toLowerCase();
    console.log(keyword);
    $('.note-share-list .list-group-item').each(function () {
        var item = $(this).attr('data-name');
        if (item) {
            if (item.toLowerCase().indexOf(keyword) == -1) {
                $(this).addClass('d-none');
            } else {
                $(this).removeClass('d-none');
            }
        }
    });
});