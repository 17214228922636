$(document).on('turbolinks:load', function () {
    if ($('.decryped-hash').length > 0) {
        setTimeout(function () {
            window.location.href = "/";
        }, 1000);
    };
    $.rails.refreshCSRFTokens();
    csrf = $('[name="csrf-token"]').attr('content');
    $.ajaxSetup({
        beforeSend: function (xhr) {
            xhr.setRequestHeader("Content-Type", "application/json");
            xhr.setRequestHeader("X-CSRF-Token", csrf);
        }
    });

    var sBrowser, sUsrAg = navigator.userAgent;
    var x = $(window).height();
    if (sUsrAg.indexOf("Firefox") > -1) {
        console.log("FIREFOX");
        x = $(window).height() - 40;
    }
    // Auto height

    console.log('Window Height', x);
    var y = $('.search-con').height();
    console.log('Search Height', y);
    $('.admin-view .search-section .user-list').css('margin-top', y + 20);
    $('.admin-view .search-section .user-list').css('height', x - y + 20);
    // if ($('.search-section .list-group-item.active').length > 0) {
    //     var itemScrollTop = $('.search-section .list-group-item.active')[0].offsetTop;
    //     $(".search-section .list-group").scrollTop(itemScrollTop - y - 20);
    // }

    // Load Datetime Picker
    $('#dob').datetimepicker({
        format: 'DD-MM-YYYY',
        maxDate: moment().add(1, "minutes"),
        minDate: moment().subtract(50, "years"),
        ignoreReadonly: true,
        icons: {
            time: 'fa fa-time',
            date: 'fa fa-calendar',
            up: 'fa fa-chevron-up',
            down: 'fa fa-chevron-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-screenshot',
            clear: 'fa fa-trash',
            close: 'fa fa-remove'
        }
    });
    $('#edit_dob').datetimepicker({
        format: 'DD-MM-YYYY',
        maxDate: moment().add(1, "minutes"),
        minDate: moment().subtract(50, "years"),
        ignoreReadonly: true,
        icons: {
            time: 'fa fa-time',
            date: 'fa fa-calendar',
            up: 'fa fa-chevron-up',
            down: 'fa fa-chevron-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-screenshot',
            clear: 'fa fa-trash',
            close: 'fa fa-remove'
        }
    });
    if ($('.flash-messages .alert').length > 0) {
        setTimeout(function () {
            $('.flash-messages').empty();
        }, 3000);
    }

    $('.user-list .show-assigned').each(function () {
        var existing = $(this).html();
        console.log('SHOW ASSIGNED', existing);
        $(this).html('<span class="badge badge-success badge-pill">Assigned!</span>');
        var cur = $(this);
        setTimeout(function () {
            cur.html(existing);
            console.log('REMOVE ASSIGNED');
        }, 10000);
    });

    var myid = $('body').attr('data-myid');
    console.log(myid);
    if (myid != undefined) {
        // Listen
        var pusher = new Pusher('41c576be2ef9f65ec9d5', {
            cluster: 'ap2'
        });

        var channel = pusher.subscribe(myid);
        var creation_channel = pusher.subscribe("GLOBAL");
        channel.unbind_all(function () {
            console.log('UNBINDED ALL');
        });
        creation_channel.unbind_all(function () {
            console.log('UNBINDED ALL');
        });

        creation_channel.bind('client_created', function (data) {
            console.log("CLIENT ADDED", data);
            if ($('.allclient-list').find('.list-group-item[data-clientid="' + data.client._id + '"]').length > 0) {
                console.log('Already Added');
            } else {
                var row = '<a href="/allclients/' + data.client._id + '/all"  data-clientid="' + data.client._id + '" data-patientid="' + data.client.patientid + '" data-name="' + data.client.firstname + " " + data.client.lastname + '" class="list-group-item "><div class="row"><div class="col-2"><img src="' + data.client.dp + '" alt="profile" width="40px"></div><div class="col-7"><p>' + data.client.firstname + " " + data.client.lastname + '</p><p style="padding: 0;"><small>' + data.client.patientid + '</small></p></div><div class="col-3 text-right"></div></div></a>';
                $('.search-section .allclient-list').prepend(row);
                $('.search-section .allclient-list').scrollTop(0);
            }
        });
        channel.bind('client_added', function (data) {
            console.log("CLIENT ADDED", data);
            if ($('.search-section .user-list').find('.list-group-item[data-clientid="' + data.client._id + '"]').length > 0) {
                console.log('Already Added');
            } else {
                var userstatus = "EXISTING";
                var pather = "clients";
                if (data.client.is_new) {
                    userstatus = "NEW";
                }
                if ((data.role === "coordinator")) {
                    pather = "coordinate";
                }
                var row = '<a href="/' + pather + '/' + data.client._id + '/all"  data-clientid="' + data.client._id + '" data-patientid="' + data.client.patientid + '" data-name="' + data.client.firstname + " " + data.client.lastname + '" class="list-group-item "><div class="row"><div class="col-2"><img src="' + data.client.dp + '" alt="profile" width="40px"></div><div class="col-7"><p>' + data.client.firstname + " " + data.client.lastname + '</p><p style="padding: 0;"><small>' + data.client.patientid + '</small></p></div><div class="col-3 text-right"><span class="badge badge-success badge-pill">' + userstatus + '</span></div></div></a>';
                if (data.role === "coordinator") {
                    $('.search-section .coordinate-list .tobe_list .null-state-list').remove();
                    $('.search-section .coordinate-list .tobe_list').append(row);
                    $('.search-section .coordinate-list .tobe_list').scrollTop(0);
                } else {
                    $('.search-section .myclient-list').prepend(row);
                    $('.search-section .myclient-list').scrollTop(0);
                }
            }
        });
        channel.bind('client_removed', function (data) {
            console.log("CLIENT REMOVED", data);
            if (data.role === "coordinator") {
                $('.search-section .coordinate-list').find('.list-group-item[data-clientid="' + data.client._id + '"]').remove();
            } else {
                $('.search-section .myclient-list').find('.list-group-item[data-clientid="' + data.client._id + '"]').remove();
            }
        });
    }


    jQuery.validator.addMethod("emailExt", function (value, element, param) {
        return value.match(/^[a-zA-Z0-9_\.%\+\-]+@[a-zA-Z0-9\.\-]+\.[a-zA-Z]{1,}$/);
    }, 'Please enter a valid email address.');
    jQuery.validator.addMethod("phoneExt", function (value, element, param) {
        return value.match(/^[\s()+-]*([0-9][\s()+-]*){6,20}$/);
    }, 'Please enter a valid phone number');

    // Dealing with Internation ISD Codes
    $('input[type="tel"]').intlTelInput({
        initialCountry: "auto",
        separateDialCode: true,
        utilsScript: "/utils.js",
        geoIpLookup: function (callback) {
            $.get('//ipinfo.io/?token=7a78bc77b08ff4', function () { }, "jsonp").always(function (resp) {
                var countryCode = (resp && resp.country) ? resp.country : "";
                callback(countryCode);
            });
        }
    });
});