// Upload Profile
$(document).on('click', '#dp', function () {
    $('#dp_upload').click();
});

$(document).on('change', '#dp_upload', function (e) {
    var blob = $(this)[0].files[0];
    var filename = $(this)[0].files[0].name;
    var ext = filename.split('.').pop().toLowerCase();
    if ((ext === "png") || (ext === "jpg") || (ext === "jpeg")) {
        console.log(filename);
        console.log(blob);
        var storageRef = firebase.storage().ref();
        var uploadTask = storageRef.child('cf/' + filename).put(blob);

        uploadTask.on('state_changed', function (snapshot) {
            $('#dp').html('<span class="fa fa-circle-o-notch fa-spin"></span>');
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
            }
        }, function (error) {
            // Handle unsuccessful uploads
        }, function () {
            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                console.log('File available at', downloadURL);
                $('.profile-dp').attr('src', downloadURL);
                $('#dp').html('UPLOAD / CHANGE PHOTO');
            });
        });
    } else {
        alert("Only PNG, JPG and JPEG allowed");
    }
});

// Edit Profile
// Upload Profile
$(document).on('click', '#edit_dp', function () {
    $('#edit_dp_upload').click();
});

$(document).on('change', '#edit_dp_upload', function (e) {
    var blob = $(this)[0].files[0];
    var filename = $(this)[0].files[0].name;
    var ext = filename.split('.').pop().toLowerCase();
    if ((ext === "png") || (ext === "jpg") || (ext === "jpeg")) {
        console.log(filename);
        console.log(blob);
        var storageRef = firebase.storage().ref();
        var uploadTask = storageRef.child('cf/' + filename).put(blob);

        uploadTask.on('state_changed', function (snapshot) {
            $('#edit_dp').html('<span class="fa fa-circle-o-notch fa-spin"></span>');
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
            }
        }, function (error) {
            // Handle unsuccessful uploads
        }, function () {
            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                console.log('File available at', downloadURL);
                $('.edit-profile-dp').attr('src', downloadURL);
                $('#edit_dp').html('UPLOAD / CHANGE PHOTO');
            });
        });
    } else {
        alert("Only PNG, JPG and JPEG allowed");
    }
});

$(document).on('click', '.note-upload', function () {
    $('#note_doc_upload').click();
});

$(document).on('change', '#note_doc_upload', function (e) {
    var user_id = $('.profile-view').attr('data-profile');
    var blob = $(this)[0].files[0];
    var filename = $(this)[0].files[0].name;
    var ext = filename.split('.').pop().toLowerCase();
    if ((ext === "png") || (ext === "jpg") || (ext === "jpeg") || (ext === "pdf")) {
        console.log(filename);
        console.log(blob);
        var storageRef = firebase.storage().ref();
        var uploadTask = storageRef.child('cf/' + filename).put(blob);

        uploadTask.on('state_changed', function (snapshot) {
            $('.note-upload').html('<span class="fa fa-circle-o-notch fa-spin"></span>');
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
            }
        }, function (error) {
            // Handle unsuccessful uploads
        }, function () {
            $('.upload-help').text('Uploaded!');
            $('.note-upload').html('<span class="fa fa-pencil"></span>');
            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                console.log('File available at', downloadURL);
                $('.save-draft-notes').attr('data-upload', downloadURL);
                $('.add-notes').attr('data-upload', downloadURL);
            });
        });
    } else {
        alert("Only PDF, PNG, JPG and JPEG allowed");
    }
});

$(document).on('click', '.note-upload-edit', function () {
    $('#note_doc_upload_edit').click();
});

$(document).on('change', '#note_doc_upload_edit', function (e) {
    var user_id = $('.profile-view').attr('data-profile');
    var blob = $(this)[0].files[0];
    var filename = $(this)[0].files[0].name;
    var ext = filename.split('.').pop().toLowerCase();
    if ((ext === "png") || (ext === "jpg") || (ext === "jpeg") || (ext === "pdf")) {
        console.log(filename);
        console.log(blob);
        var storageRef = firebase.storage().ref();
        var uploadTask = storageRef.child('cf/' + filename).put(blob);

        uploadTask.on('state_changed', function (snapshot) {
            $('.note-upload-edit').html('<span class="fa fa-circle-o-notch fa-spin"></span>');
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
            }
        }, function (error) {
            // Handle unsuccessful uploads
        }, function () {
            $('.upload-help').text('Uploaded!');
            $('.note-upload-edit').html('<span class="fa fa-pencil"></span>');
            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                console.log('File available at', downloadURL);
                $('.edit-notes').attr('data-upload', downloadURL);
                $('.edit-draft-notes').attr('data-upload', downloadURL);
                var extension = downloadURL.split("?")[0].split('.').pop();
                extension = extension.toLowerCase();
                console.log("EXTENSION IS ", extension);
                if (extension === "pdf") {
                    var row = '<button type="button" data-path="' + downloadURL + '" class="btn btn-primary show-attachment"><span class="fa fa-eye"></span></button>';
                }
                if ((extension === "png") || (extension === "jpg") || (extension === "jpeg")) {
                    var row = '<a href="' + downloadURL + '" type="button" class="btn btn-primary show-attachment" data-lightbox="preview" data-title="Draft Attachment"><span class="fa fa-eye"></span></a>';
                }
                $('.doc-uploaded-state').removeClass('d-none');
                $('.doc-uploaded-state').html(row);
            });
        });
    } else {
        alert("Only PDF, PNG, JPG and JPEG allowed");
    }
});

$(document).on('click', '.upload-trig', function () {
    $('#doc_upload').click();
});

$(document).on('change', '#doc_upload', function (e) {
    var user_id = $('.profile-view').attr('data-profile');
    var blob = $(this)[0].files[0];
    var filename = $(this)[0].files[0].name;
    var ext = filename.split('.').pop().toLowerCase();
    if ((ext === "png") || (ext === "jpg") || (ext === "jpeg") || (ext === "pdf")) {
        console.log(filename);
        console.log(blob);
        var storageRef = firebase.storage().ref();
        var uploadTask = storageRef.child('cf/' + filename).put(blob);

        uploadTask.on('state_changed', function (snapshot) {
            $('.upload-con').addClass('d-none');
            $('.progress-con').removeClass('d-none');
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');

            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    $('.progress-bar').css('width', progress + '%')
                    break;
            }
        }, function (error) {
            // Handle unsuccessful uploads
        }, function () {
            $('.progress-bar').css('width', '100%');
            $('.progress-con').addClass('d-none');
            setTimeout(function () {
                $('#upload-files').modal('hide');
            }, 500);
            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                console.log('File available at', downloadURL);
                payload = {
                    "user_id": user_id,
                    "filename": filename,
                    "path": downloadURL
                }
                $.ajax({
                    url: "/addfile",
                    type: "POST",
                    data: JSON.stringify(payload),
                    success: function (resp) {
                        console.log(resp);
                        window.location.reload();
                    },
                    error: function (err) {
                        console.log(err);
                    }
                });
            });
        });
    } else {
        alert("Only PDF, PNG, JPG and JPEG allowed");
    }
});

$(document).on('click', '#upload_signature', function () {
    console.log('click action');
    $('#signature_upload').click();
});

$(document).on('click', '#edit_signature', function () {
    console.log('click action');
    $('#signature_upload').click();
});

$(document).on('change', '#signature_upload', function (e) {
    var user_id = $('.profile-view').attr('data-profile');
    var blob = $(this)[0].files[0];
    var filename = $(this)[0].files[0].name;
    var ext = filename.split('.').pop().toLowerCase();
    if ((ext === "png") || (ext === "jpg") || (ext === "jpeg") || (ext === "pdf")) {
        console.log(filename);
        console.log(blob);
        var storageRef = firebase.storage().ref();
        var uploadTask = storageRef.child('cf/' + filename).put(blob);
        uploadTask.on('state_changed', function (snapshot) {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    $('#upload_signature').html('<span class="spinner-border spinner-border-sm"></span> &nbsp;Uploading...').attr('disabled', true);
                    console.log('Upload is running');
                    break;
            }
        }, function (error) {
            // Handle unsuccessful uploads
        }, function () {
            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                console.log('File available at', downloadURL);
                $('#upload_signature').removeClass('btn-outline-primary').addClass('btn-secondary');
                $('#upload_signature').html('<span class="fa fa-pencil"></span> &nbsp;e-signature uploaded');
                $('#upload_signature').attr('data-url', downloadURL);
                $('#edit_signature').removeClass('d-none');
            });
        });
    } else {
        alert("Only PDF, PNG, JPG and JPEG allowed");
    }
});

$(document).on('click', '#upload_photoid', function () {
    $('#copyid_upload').click();
});

$(document).on('click', '#edit_photoid', function () {
    $('#copyid_upload').click();
});

$(document).on('change', '#copyid_upload', function (e) {
    var user_id = $('.profile-view').attr('data-profile');
    var blob = $(this)[0].files[0];
    var filename = $(this)[0].files[0].name;
    var ext = filename.split('.').pop().toLowerCase();
    if ((ext === "png") || (ext === "jpg") || (ext === "jpeg") || (ext === "pdf")) {
        console.log(filename);
        console.log(blob);
        var storageRef = firebase.storage().ref();
        var uploadTask = storageRef.child('cf/' + filename).put(blob);

        uploadTask.on('state_changed', function (snapshot) {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    $('#upload_photoid').html('<span class="spinner-border spinner-border-sm"></span> &nbsp;Uploading...').attr('disabled', true);
                    console.log('Upload is running');
                    break;
            }
        }, function (error) {
            // Handle unsuccessful uploads
        }, function () {
            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                console.log('File available at', downloadURL);
                $('#upload_photoid').removeClass('btn-outline-primary').addClass('btn-secondary');
                $('#upload_photoid').html('<span class="fa fa-pencil"></span> &nbsp;photo id uploaded');
                $('#upload_photoid').attr('data-url', downloadURL);
                $('#edit_photoid').removeClass('d-none');
            });
        });
    } else {
        alert("Only PDF, PNG, JPG and JPEG allowed");
    }
});

// EDIT PROFILE
$(document).on('click', '#edit_upload_signature', function () {
    console.log('click action');
    $('#edit_signature_upload').click();
});

$(document).on('click', '#edit_edit_signature', function () {
    console.log('click action');
    $('#edit_signature_upload').click();
});

$(document).on('change', '#edit_signature_upload', function (e) {
    var user_id = $('.profile-view').attr('data-profile');
    var blob = $(this)[0].files[0];
    var filename = $(this)[0].files[0].name;
    var ext = filename.split('.').pop().toLowerCase();
    if ((ext === "png") || (ext === "jpg") || (ext === "jpeg") || (ext === "pdf")) {
        console.log(filename);
        console.log(blob);
        var storageRef = firebase.storage().ref();
        var uploadTask = storageRef.child('cf/' + filename).put(blob);
        uploadTask.on('state_changed', function (snapshot) {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    $('#edit_upload_signature').html('<span class="spinner-border spinner-border-sm"></span> &nbsp;Uploading...').attr('disabled', true);
                    console.log('Upload is running');
                    break;
            }
        }, function (error) {
            // Handle unsuccessful uploads
        }, function () {
            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                console.log('File available at', downloadURL);
                $('#edit_upload_signature').removeClass('btn-outline-primary').addClass('btn-secondary');
                $('#edit_upload_signature').html('<span class="fa fa-pencil"></span> &nbsp;e-signature uploaded');
                $('#edit_upload_signature').attr('data-url', downloadURL);
                $('#edit_edit_signature').removeClass('d-none');
            });
        });
    } else {
        alert("Only PDF, PNG, JPG and JPEG allowed");
    }
});

$(document).on('click', '#edit_upload_photoid', function () {
    $('#edit_copyid_upload').click();
});

$(document).on('click', '#edit_edit_photoid', function () {
    $('#edit_copyid_upload').click();
});

$(document).on('change', '#edit_copyid_upload', function (e) {
    var user_id = $('.profile-view').attr('data-profile');
    var blob = $(this)[0].files[0];
    var filename = $(this)[0].files[0].name;
    var ext = filename.split('.').pop().toLowerCase();
    if ((ext === "png") || (ext === "jpg") || (ext === "jpeg") || (ext === "pdf")) {
        console.log(filename);
        console.log(blob);
        var storageRef = firebase.storage().ref();
        var uploadTask = storageRef.child('cf/' + filename).put(blob);

        uploadTask.on('state_changed', function (snapshot) {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    $('#edit_upload_photoid').html('<span class="spinner-border spinner-border-sm"></span> &nbsp;Uploading...').attr('disabled', true);
                    console.log('Upload is running');
                    break;
            }
        }, function (error) {
            // Handle unsuccessful uploads
        }, function () {
            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                console.log('File available at', downloadURL);
                $('#edit_upload_photoid').removeClass('btn-outline-primary').addClass('btn-secondary');
                $('#edit_upload_photoid').html('<span class="fa fa-pencil"></span> &nbsp;photo id uploaded');
                $('#edit_upload_photoid').attr('data-url', downloadURL);
                $('#edit_edit_photoid').removeClass('d-none');
            });
        });
    } else {
        alert("Only PDF, PNG, JPG and JPEG allowed");
    }
});
