$(document).on('click', '.btn-close-add-client', function () {
    $('#addclient02').modal('hide');
    $('#addclient01').modal('hide');
    window.location.reload();
});
$(document).on('click', '.btn-close-edit-client', function () {
    $('#editclient01').modal('hide');
    $('#editclient02').modal('hide');
    window.location.reload();
});
$(document).on('click', '.client-step-prev-2', function () {
    $('#parent_email-error').remove();
    $('#parent_mobile-error').remove();
    $('#addclient02').modal('hide');
    $('#addclient01').modal('show');
});
var subValidation = function () {
    $("#parent_email").rules("add", {
        required: window.lessthan18,
        email: window.lessthan18,
        emailExt: window.lessthan18
    });
    $("#parent_mobile").rules("add", {
        required: window.lessthan18,
        phoneExt: window.lessthan18
    });
}

window.lessthan18 = true;
$(document).on('turbolinks:load', function () {
    $("#new-client-1").validate({
        rules: {
            firstname: {
                required: true
            },
            lastname: {
                required: true
            },
            patientid: {
                required: true
            },
            dob: {
                required: true
            },
            landline: {
                number: true
            },
            mobile: {
                required: true,
                phoneExt: true
            },
            email: {
                required: true,
                email: true,
                emailExt: true
            }
        },
        submitHandler: function (form) {
            console.log('form', form);
            var dob = moment($('#dob').val(), 'DD-MM-YYYY');
            var years = moment().diff(dob, 'years');
            if (years >= 18) {
                window.lessthan18 = false;
            } else {
                window.lessthan18 = true;
            }
            console.log('DOB YEARS', window.lessthan18, years);
            var payload = {
                email: $('#email').val(),
                mobile: $('#mobile').intlTelInput("getNumber"),
                patientid: $('#patientid').val()
            };
            console.log("PRECHECK PAYLOAD", payload);
            $.ajax({
                url: "/precheck",
                type: "POST",
                data: JSON.stringify(payload),
                success: function (resp) {
                    console.log("SUCCESS", resp);
                    subValidation();
                    $('#addclient01').modal('hide');
                    $('#addclient02').modal('show');
                },
                error: function (err) {
                    console.log("FAILURE", err.responseJSON);
                    var response = err.responseJSON;
                    if (!response.patientid) {
                        $("#new-client-1").validate().showErrors({
                            "patientid": "Patient ID Exists"
                        });
                    }
                    if (!response.mobile) {
                        $("#new-client-1").validate().showErrors({
                            "mobile": "Mobile Number Exists"
                        });
                    }
                    if (!response.email) {
                        $("#new-client-1").validate().showErrors({
                            "email": "Email ID Exists"
                        });
                    }
                }
            });
        }
    });
    $("#new-client-2").validate({
        rules: {
            parent_email: {
                required: window.lessthan18,
                email: window.lessthan18,
                emailExt: window.lessthan18
            },
            parent_mobile: {
                required: window.lessthan18,
                phoneExt: window.lessthan18
            }
        },
        submitHandler: function (form) {
            $('.client-step-next-2').attr('disabled', true);
            var patientid = $('#patientid').val();
            var firstname = $('#fistname').val();
            var lastname = $('#lastname').val();
            var dob = $('#dob').datetimepicker({ dateFormat: 'dd-mm-yy' }).val();
            var gender = $('#gender').val();
            if (gender === "Gender") {
                gender = ""
            }
            var address = $('#address').val();
            var landline = $('#landline').val();
            var mobile = $('#mobile').intlTelInput("getNumber");
            var email = $('#email').val();
            var skype = $('#skype').val();
            var zoom = $('#zoom').val();
            var parent_email = $('#parent_email').val();
            var parent_mobile = $('#parent_mobile').intlTelInput("getNumber");
            var esignature = $('#upload_signature').attr('data-url');
            var photoid = $('#upload_photoid').attr('data-url');
            var placeofwork = $('#placeofwork').val();
            var yearofwork = $('#yearofwork').val();
            var referalsource = $('#referalsource').val();
            var is_new = $('#existingclient:checked').val() === "on" ? false : true;
            payload = {
                "patientid": patientid,
                "firstname": firstname,
                "lastname": lastname,
                "dob": dob,
                "gender": gender,
                "address": address,
                "landline": landline,
                "mobile": mobile,
                "email": email,
                "skype": skype,
                "zoom": zoom,
                "parent_email": parent_email,
                "parent_mobile": parent_mobile,
                "esignature": esignature,
                "photoid": photoid,
                "placeofwork": placeofwork,
                "yearofwork": yearofwork,
                "referalsource": referalsource,
                "is_new": is_new
            };
            console.log(payload);
            $.ajax({
                url: "/createclient",
                type: "POST",
                data: JSON.stringify(payload),
                success: function (resp) {
                    console.log(resp);
                    $('#addclient02').modal('hide');
                    window.location.reload();
                },
                error: function (err) {
                    console.log(err);
                }
            });
        }
    });
});