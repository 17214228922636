// Add Notes
$(document).on('click', '.open-notes-form', function () {
    $('#private_notes').prop('checked', false);
    $('.note-sharables').empty();
    $('.upload-help').text('Upload Document');
    $('#notes_title').val('');
    $('#notes_description').val('');
    $('.add-notes').attr('data-upload', "");
});

$(document).on('click', '.add-client-note', function () {
    var notes_type = $(this).attr('data-note-type');
    console.log(notes_type);
    var modal_title = "";
    var title_placeholder = "";
    var description_placeholder = "";
    switch (notes_type) {
        case "note":
            modal_title = "Add Session Notes"
            title_placeholder = "Notes Title"
            description_placeholder = "Notes Description"
            break;
        case "communication":
            modal_title = "Add Communication"
            title_placeholder = "Communication Title"
            description_placeholder = "Description"
            break;
        case "record":
            modal_title = "Add Client Record"
            title_placeholder = "Record Title"
            description_placeholder = "Record Description"
            break;
        default:
            console.log("DONT UPLOAD ELSE");
            break;
    }
    $('#add-notes .share-trigger').addClass('d-none');
    $('#add-notes .modal-title').text(modal_title);
    $('#notes_title').attr('placeholder', title_placeholder);
    $('#notes_description').attr('placeholder', description_placeholder);
    $('.save-draft-notes').attr('data-note-type', notes_type);
    $('.add-notes').attr('data-note-type', notes_type);
    $('.edit-notes').attr('data-note-type', notes_type);
    $('#add-notes').modal('show');
});

$(document).on('change', '#private_notes', function () {
    if (this.checked) {
        $('.share-trigger').removeClass('d-none');
    } else {
        $('.note-sharables').empty();
        $('.share-trigger').addClass('d-none');
    }
});
$(document).on('change', '#private_notes_edit', function () {
    if (this.checked) {
        $('.share-trigger-edit').removeClass('d-none');
    } else {
        $('.note-sharables-edit').empty();
        $('.share-trigger-edit').addClass('d-none');
    }
});
$(document).on('change', '#private_notes_edit_partial', function () {
    if (this.checked) {
        $('.share-trigger-partial').removeClass('d-none');
    } else {
        $('.note-sharables-edit-partial').empty();
        $('.share-trigger-partial').addClass('d-none');
    }
});

// Add Notes Shares

$(document).on('click', '.share-trigger', function () {
    $('#add-shares .share-with-users-note:checked').each(function () {
        $(this).prop('checked', false);
    });
    var counter = 0;
    var shared_with = [];
    $('.note-sharables .chip').each(function () {
        var sharableid = $(this).attr('data-shareid');
        shared_with.push(sharableid);
    });
    uniqueloop = _.uniq(shared_with);
    uniqueloop.forEach(function (item, index) {
        console.log("MARK ", item);
        $('#add-shares .share-with-users-note[data-id="' + item + '"]').prop('checked', true);
        counter = counter + 1;
    });
    console.log("COUNTER ", counter);
    $('#add-shares .share-count').text(counter);
    $('#add-shares').modal('show');
});

$(document).on('click', '.share-with-users-note', function () {
    var counter = 0;
    $('#add-shares .share-with-users-note:checked').each(function () {
        counter = counter + 1;
    });
    $('#add-shares .share-count').text(counter);
});

// Edit Notes Shares

$(document).on('click', '.share-trigger-edit', function () {
    $('#add-shares .share-with-users-note:checked').each(function () {
        $(this).prop('checked', false);
    });
    var counter = 0;
    var shared_with = [];
    $('.note-sharables-edit .chip').each(function () {
        var sharableid = $(this).attr('data-shareid');
        shared_with.push(sharableid);
    });
    uniqueloop = _.uniq(shared_with);
    uniqueloop.forEach(function (item, index) {
        console.log("MARK ", item);
        $('#add-shares .share-with-users-note[data-id="' + item + '"]').prop('checked', true);
        counter = counter + 1;
    });
    console.log("COUNTER ", counter);
    $('#add-shares .share-count').text(counter);
    $('#add-shares').modal('show');
});

// Partial Notes Shares

$(document).on('click', '.share-trigger-partial', function () {
    $('#add-shares .share-with-users-note:checked').each(function () {
        $(this).prop('checked', false);
    });
    var counter = 0;
    var shared_with = [];
    $('.note-sharables-edit-partial .chip').each(function () {
        var sharableid = $(this).attr('data-shareid');
        shared_with.push(sharableid);
    });
    uniqueloop = _.uniq(shared_with);
    uniqueloop.forEach(function (item, index) {
        console.log("MARK ", item);
        $('#add-shares .share-with-users-note[data-id="' + item + '"]').prop('checked', true);
        counter = counter + 1;
    });
    console.log("COUNTER ", counter);
    $('#add-shares .share-count').text(counter);
    $('#add-shares').modal('show');
});

$(document).on('turbolinks:load', function () {
    $("#add-notes-form").validate({
        rules: {
            notes_title: {
                required: true
            },
            notes_description: {
                required: true
            }
        },
        submitHandler: function (form) {
            console.log('form', form);
            $('.add-notes').attr('disabled', true);
            $('.save-draft-notes').attr('disabled', true);
            var typeofnotes = $('.add-notes').attr('data-note-type');
            var path = $('.add-notes').attr('data-upload');
            var user_id = $('.profile-view').attr('data-profile');
            var private_notes = $('#private_notes').is(':checked');
            var title = $('#notes_title').val();
            var description = $('#notes_description').val();
            var shared_with = [];
            $('.chip').each(function () {
                var sharableid = $(this).attr('data-shareid');
                shared_with.push(sharableid);
            });
            var payload = {
                "user_id": user_id,
                "title": title,
                "body": description,
                "is_private": private_notes,
                "is_draft": false,
                "type": typeofnotes,
                "path": path,
                "shared_with": shared_with
            }
            console.log(payload);
            $.ajax({
                url: "/addnotes",
                type: "POST",
                data: JSON.stringify(payload),
                success: function (resp) {
                    console.log(resp);
                    $('#add-notes').modal('hide');
                    window.location.reload();
                },
                error: function (err) {
                    console.log(err);
                }
            });
        }
    });

    $("#edit-notes-form").validate({
        rules: {
            notes_title_edit: {
                required: true
            },
            notes_description_edit: {
                required: true
            }
        },
        submitHandler: function (form) {
            console.log('form', form);
            $('.edit-draft-notes').attr('disabled', true);
            $('.edit-notes').attr('disabled', true);
            var typeofnotes = $('.edit-notes').attr('data-note-type');
            var path = $('.edit-notes').attr('data-upload');
            var note_id = $('.edit-notes').attr('data-note');
            var user_id = $('.profile-view').attr('data-profile');
            var private_notes = $('#private_notes_edit').is(':checked');
            var title = $('#notes_title_edit').val();
            var description = $('#notes_description_edit').val();
            var shared_with = [];
            $('.chip').each(function () {
                var sharableid = $(this).attr('data-shareid');
                shared_with.push(sharableid);
            });
            var payload = {
                "user_id": user_id,
                "title": title,
                "body": description,
                "is_private": private_notes,
                "is_draft": false,
                "type": typeofnotes,
                "path": path,
                "shared_with": shared_with
            }
            console.log(payload);
            $.ajax({
                url: "/updatenotes/" + note_id,
                type: "POST",
                data: JSON.stringify(payload),
                success: function (resp) {
                    console.log(resp);
                    $('#edit-notes').modal('hide');
                    window.location.reload();
                },
                error: function (err) {
                    console.log(err);
                }
            });
        }
    });
});

$(document).on('click', '.save-draft-notes', function () {
    $('.add-notes').attr('disabled', true);
    $('.save-draft-notes').attr('disabled', true);
    var typeofnotes = $(this).attr('data-note-type');
    var path = $(this).attr('data-upload');
    var user_id = $('.profile-view').attr('data-profile');
    var private_notes = $('#private_notes').is(':checked');
    var title = $('#notes_title').val();
    var description = $('#notes_description').val();
    var shared_with = [];
    $('.chip').each(function () {
        var sharableid = $(this).attr('data-shareid');
        shared_with.push(sharableid);
    });
    var payload = {
        "user_id": user_id,
        "title": title,
        "body": description,
        "is_private": private_notes,
        "is_draft": true,
        "type": typeofnotes,
        "path": path,
        "shared_with": shared_with
    }
    console.log(payload);
    $.ajax({
        url: "/addnotes",
        type: "POST",
        data: JSON.stringify(payload),
        success: function (resp) {
            console.log(resp);
            $('#add-notes').modal('hide');
            window.location.reload();
        },
        error: function (err) {
            console.log(err);
        }
    });
});

$(document).on('click', '.edit-draft-notes', function () {
    $('.edit-draft-notes').attr('disabled', true);
    $('.edit-notes').attr('disabled', true);
    var typeofnotes = $(this).attr('data-note-type');
    var path = $(this).attr('data-upload');
    var note_id = $(this).attr('data-note');
    var user_id = $('.profile-view').attr('data-profile');
    var private_notes = $('#private_notes_edit').is(':checked');
    var title = $('#notes_title_edit').val();
    var description = $('#notes_description_edit').val();
    var shared_with = [];
    $('.chip').each(function () {
        var sharableid = $(this).attr('data-shareid');
        shared_with.push(sharableid);
    });
    var payload = {
        "user_id": user_id,
        "title": title,
        "body": description,
        "is_private": private_notes,
        "is_draft": true,
        "type": typeofnotes,
        "path": path,
        "shared_with": shared_with
    }
    console.log(payload);
    $.ajax({
        url: "/updatenotes/" + note_id,
        type: "POST",
        data: JSON.stringify(payload),
        success: function (resp) {
            console.log(resp);
            $('#edit-notes').modal('hide');
            window.location.reload();
        },
        error: function (err) {
            console.log(err);
        }
    });
});

$(document).on('click', '.note-editor', function () {
    var note_id = $(this).attr('data-note');
    var notes_type = $(this).attr('data-note-type');
    console.log(notes_type);
    var modal_title = "";
    var title_placeholder = "";
    var description_placeholder = "";
    switch (notes_type) {
        case "note":
            modal_title = "Edit/View Session Notes"
            title_placeholder = "Notes Title"
            description_placeholder = "Notes Description"
            break;
        case "communication":
            modal_title = "Edit/View Communications"
            title_placeholder = "Communication Title"
            description_placeholder = "Description"
            break;
        case "record":
            modal_title = "Edit/View Client Records"
            title_placeholder = "Record Title"
            description_placeholder = "Record Description"
            break;
        default:
            console.log("DONT UPLOAD ELSE");
            break;
    }
    $('.edit-draft-notes').attr('data-note-type', notes_type);
    $('#edit-notes .modal-title').text(modal_title);
    $('#notes_title_edit').attr('placeholder', title_placeholder);
    $('#notes_description_edit').attr('placeholder', description_placeholder);
    console.log(note_id);
    $.ajax({
        url: "/notes/" + note_id,
        type: "GET",
        success: function (resp) {
            console.log(resp);
            $('.edit-notes').attr('data-note', resp.note._id);
            $('.edit-draft-notes').attr('data-note', resp.note._id);
            $('.edit-notes').attr('data-upload', resp.note.path);
            $('.edit-draft-notes').attr('data-upload', resp.note.path);
            $('.draft-delete').attr('data-draft', resp.note._id);
            if ((resp.note.path !== null) && (resp.note.path !== "")) {
                var extension = resp.note.path.split("?")[0].split('.').pop();
                extension = extension.toLowerCase();
                console.log("EXTENSION IS ", extension);
                if (extension === "pdf") {
                    var row = '<button type="button" data-path="' + resp.note.path + '" class="btn btn-primary show-attachment"><span class="fa fa-eye"></span></button>';
                }
                if ((extension === "png") || (extension === "jpg") || (extension === "jpeg")) {
                    var row = '<a href="' + resp.note.path + '" type="button" class="btn btn-primary show-attachment" data-lightbox="preview" data-title="Draft Attachment"><span class="fa fa-eye"></span></a>';
                }
                $('.doc-uploaded-state').removeClass('d-none');
                $('.doc-uploaded-state').html(row);
                // Already Uploaded State
                $('.note-upload-edit').html('<span class="fa fa-pencil"></span>');
                $('.edit-upload-help').text('Uploaded!');
            } else {
                // Empty Download Button
                $('.doc-uploaded-state').addClass('d-none');
                $('.doc-uploaded-state').html("");
                // Empty Upload State
                $('.note-upload-edit').html('<span class="fa fa-plus"></span>');
                $('.edit-upload-help').text('Upload Document');
            }
            if (resp.note.is_private) {
                $('#private_notes_edit').prop('checked', true);
                $('.share-trigger-edit').removeClass('d-none');
            } else {
                $('#private_notes_edit').prop('checked', false);
                $('.share-trigger-edit').addClass('d-none');
            }
            $('.note-sharables-edit').empty();
            resp.shared.forEach(function (user) {
                console.log("SHARED WITH ", user);
                var row = '<div class="chip" data-shareid="' + user._id + '"><div class="chip-content">' + user.firstname + ' ' + user.lastname + '</div><div class="chip-close" data-id="' + user._id + '"><svg class="chip-svg" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path></svg></div></div>';
                $('.note-sharables-edit').append(row);
            });
            if (resp.note.title !== "") {
                $('#notes_title_edit').val(resp.note.title);
            }
            if (resp.note.body !== "") {
                var element = document.querySelector("#edit-notes trix-editor");
                element.editor.loadHTML(resp.note.body);
            }
            $('.edit-notes').attr('data-note-type', notes_type);
            $('#edit-notes').modal('show');
        },
        error: function (err) {
            console.log(err);
        }
    });
});

$(document).on('click', '.show-attachment', function () {
    var path = $(this).attr('data-path');
    console.log(path);
    var extension = path.split("?")[0].split('.').pop();
    extension = extension.toLowerCase();
    console.log(extension);
    if (extension === "pdf") {
        $('#view-pdf').modal('show');
        var url = path;
        // Loaded via <script> tag, create shortcut to access PDF.js exports.
        var pdfjsLib = window['pdfjs-dist/build/pdf'];
        // The workerSrc property shall be specified.
        pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js';
        var pdfDoc = null,
            pageNum = 1,
            pageRendering = false,
            pageNumPending = null,
            scale = 1.25,
            canvas = document.getElementById('the-canvas'),
            ctx = canvas.getContext('2d');

        /**
         * Get page info from document, resize canvas accordingly, and render page.
         * @param num Page number.
         */
        function renderPage(num) {
            pageRendering = true;
            // Using promise to fetch the page
            pdfDoc.getPage(num).then(function (page) {
                var viewport = page.getViewport({ scale: scale });
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                // Render PDF page into canvas context
                var renderContext = {
                    canvasContext: ctx,
                    viewport: viewport
                };
                var renderTask = page.render(renderContext);

                // Wait for rendering to finish
                renderTask.promise.then(function () {
                    pageRendering = false;
                    if (pageNumPending !== null) {
                        // New page rendering is pending
                        renderPage(pageNumPending);
                        pageNumPending = null;
                    }
                });
            });

            // Update page counters
            document.getElementById('page_num').textContent = num;
        }

        /**
         * If another page rendering in progress, waits until the rendering is
         * finised. Otherwise, executes rendering immediately.
         */
        function queueRenderPage(num) {
            if (pageRendering) {
                pageNumPending = num;
            } else {
                renderPage(num);
            }
        }

        /**
         * Displays previous page.
         */
        function onPrevPage() {
            if (pageNum <= 1) {
                return;
            }
            pageNum--;
            queueRenderPage(pageNum);
        }
        document.getElementById('prev').addEventListener('click', onPrevPage);

        /**
         * Displays next page.
         */
        function onNextPage() {
            if (pageNum >= pdfDoc.numPages) {
                return;
            }
            pageNum++;
            queueRenderPage(pageNum);
        }
        document.getElementById('next').addEventListener('click', onNextPage);

        /**
         * Asynchronously downloads PDF.
         */
        pdfjsLib.getDocument(url).promise.then(function (pdfDoc_) {
            pdfDoc = pdfDoc_;
            console.log(pdfDoc.numPages, "NUMBER OF PAGES!");
            if (pdfDoc.numPages == 1) {
                $('.pdf-navigation').addClass('d-none');
            } else {
                $('.pdf-navigation').removeClass('d-none');
            }
            document.getElementById('page_count').textContent = pdfDoc.numPages;

            // Initial/first page rendering
            renderPage(pageNum);
        });
    }
    if ((extension === "png") || (extension === "jpg") || (extension === "jpeg")) {
        var row = '<a href="' + path + '" data-lightbox="attachment" id="openimage">Attachment</a>';
        $('#imgpreview').html(row);
        $('#openimage').click();
    }
});

$(document).on('click', '.note-viewer', function () {
    var note_id = $(this).attr('data-note');
    console.log(note_id);
    $.ajax({
        url: "/notes/" + note_id,
        type: "GET",
        success: function (resp) {
            console.log(resp);
            if ((resp.note.path !== null) && (resp.note.path !== "")) {
                var extension = resp.note.path.split("?")[0].split('.').pop();
                extension = extension.toLowerCase();
                if (extension === "pdf") {
                    var row = '<button type="button" data-path="' + resp.note.path + '" class="btn btn-primary show-attachment"><span class="fa fa-eye"></span></button>';
                }
                if ((extension === "png") || (extension === "jpg") || (extension === "jpeg")) {
                    var row = '<a href="' + resp.note.path + '" type="button" class="btn btn-primary show-attachment" data-lightbox="preview" data-title="Attachment"><span class="fa fa-eye"></span></a>';
                }
                // Download Button
                $('.downloadable-attachment').removeClass('d-none');
                $('.downloadable-attachment').html(row);
            } else {
                // Empty Download Button
                $('.downloadable-attachment').addClass('d-none');
                $('.downloadable-attachment button').attr('data-path', "");
            }
            $('#view-only-notes .notes-title').text(resp.note.title);
            $('#view-only-notes .notes-description').html(resp.note.body);
            $('#view-only-notes').modal('show');
        },
        error: function (err) {
            console.log(err);
        }
    });
});

$(document).on('click', '.note-viewer-partial', function () {
    var note_id = $(this).attr('data-note');
    var notes_type = $(this).attr('data-note-type');
    var modal_title = "";
    switch (notes_type) {
        case "note":
            modal_title = "Edit/View Session Notes"
            break;
        case "communication":
            modal_title = "Edit/View Communications"
            break;
        case "record":
            modal_title = "Edit/View Client Records"
            break;
        default:
            console.log("DONT UPLOAD ELSE");
            break;
    }
    $('#edit-notes .modal-title').text(modal_title);
    $('.update-note-partial').attr('data-note', note_id);
    console.log(note_id);
    $.ajax({
        url: "/notes/" + note_id,
        type: "GET",
        success: function (resp) {
            console.log(resp);
            if ((resp.note.path !== null) && (resp.note.path !== "")) {
                var extension = resp.note.path.split("?")[0].split('.').pop();
                extension = extension.toLowerCase();
                if (extension === "pdf") {
                    var row = '<button type="button" data-path="' + resp.note.path + '" class="btn btn-primary show-attachment"><span class="fa fa-eye"></span></button>';
                }
                if ((extension === "png") || (extension === "jpg") || (extension === "jpeg")) {
                    var row = '<a href="' + resp.note.path + '" type="button" class="btn btn-primary show-attachment" data-lightbox="preview" data-title="Attachment"><span class="fa fa-eye"></span></a>';
                }
                // Download Button
                $('.downloadable-attachment').removeClass('d-none');
                $('.downloadable-attachment').html(row);
            } else {
                // Empty Download Button
                $('.downloadable-attachment').addClass('d-none');
                $('.downloadable-attachment').html("");
            }
            if (resp.note.is_private) {
                $('#private_notes_edit_partial').prop('checked', true);
                $('.share-trigger-partial').removeClass('d-none');
            } else {
                $('#private_notes_edit_partial').prop('checked', false);
                $('.share-trigger-partial').addClass('d-none');
            }
            $('.note-sharables-edit-partial').empty();
            resp.shared.forEach(function (user) {
                console.log("SHARED WITH ", user);
                var row = '<div class="chip" data-shareid="' + user._id + '"><div class="chip-content">' + user.firstname + ' ' + user.lastname + '</div><div class="chip-close" data-id="' + user._id + '"><svg class="chip-svg" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path></svg></div></div>';
                $('.note-sharables-edit-partial').append(row);
            });
            $('#view-only-notes-partial .notes-title').text(resp.note.title);
            $('#view-only-notes-partial .notes-description').html(resp.note.body);
            $('#view-only-notes-partial').modal('show');
        },
        error: function (err) {
            console.log(err);
        }
    });
});

$(document).on('click', '.update-note-partial', function () {
    var note_id = $(this).attr('data-note');
    var private_notes = $('#private_notes_edit_partial').is(':checked');
    var shared_with = [];
    $('.chip').each(function () {
        var sharableid = $(this).attr('data-shareid');
        shared_with.push(sharableid);
    });
    var payload = {
        "is_private": private_notes,
        "shared_with": shared_with
    }
    console.log(payload);
    $.ajax({
        url: "/notes/" + note_id,
        type: "PUT",
        data: JSON.stringify(payload),
        success: function (resp) {
            console.log(resp);
            $('#view-only-notes-partial').modal('hide');
            window.location.reload();
        },
        error: function (err) {
            console.log(err);
        }
    });
});

$(document).on('click', '.draft-delete', function () {
    var x = confirm("Are you sure you want to delete this draft?");
    if (x) {
        var note_id = $(this).attr('data-draft');
        console.log(note_id);
        $.ajax({
            url: "/notes/" + note_id,
            type: "DELETE",
            success: function (resp) {
                console.log(resp);
                window.location.reload();
            },
            error: function (err) {
                console.log(err);
            }
        });
    } else {
        return false;
        console.log('DONT DELETE');
    }
});