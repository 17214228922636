$(document).on('turbolinks:load', function () {
    $("#create-user").validate({
        rules: {
            firstname: {
                required: true
            },
            lastname: {
                required: true
            },
            mobile: {
                required: true,
                phoneExt: true
            },
            email: {
                required: true,
                email: true,
                emailExt: true
            }
        },
        submitHandler: function (form) {
            $('.create-new-user').attr('disabled', true);
            var firstname = $('#new_user_firstname').val();
            var lastname = $('#new_user_lastname').val();
            var email = $('#new_user_email').val();
            var mobile = $('#new_user_mobile').intlTelInput("getNumber");
            var usertype = $('.new_user_type:checked').val();
            var is_coordinator = ($('.new_is_coordinator:checked').val() === "on") ? true : false;
            var dp = $('.profile-dp').attr('src');
            payload = {
                "firstname": firstname,
                "lastname": lastname,
                "email": email,
                "mobile": mobile,
                "usertype": usertype,
                "dp": dp,
                "is_coordinator": is_coordinator
            };
            console.log(payload);
            $.ajax({
                url: "/createuser",
                type: "POST",
                data: JSON.stringify(payload),
                success: function (resp) {
                    console.log(resp);
                    $('#addUser').modal('hide');
                    window.location.reload();
                },
                error: function (err) {
                    console.log(err);
                }
            });
        }
    });

    $("#edit-self-form").validate({
        rules: {
            firstname: {
                required: true
            },
            lastname: {
                required: true
            },
            mobile: {
                required: true,
                phoneExt: true
            },
            email: {
                required: true,
                email: true,
                emailExt: true
            }
        },
        submitHandler: function (form) {
            var firstname = $('#edit_self_firstname').val();
            var lastname = $('#edit_self_lastname').val();
            var email = $('#edit_self_email').val();
            var mobile = $('#edit_self_mobile').intlTelInput("getNumber");
            var usertype = $('.edit_self_type:checked').val();
            var dp = $('.edit-profile-dp').attr('src');
            payload = {
                "firstname": firstname,
                "lastname": lastname,
                "email": email,
                "mobile": mobile,
                "dp": dp
            };
            console.log(payload);
            $.ajax({
                url: "/editself",
                type: "PUT",
                data: JSON.stringify(payload),
                success: function (resp) {
                    console.log(resp);
                    $('#edit-self').modal('hide');
                    window.location.reload();
                },
                error: function (err) {
                    console.log(err);
                }
            });
        }
    });

    $("#edit-user-form").validate({
        rules: {
            firstname: {
                required: true
            },
            lastname: {
                required: true
            },
            mobile: {
                required: true,
                phoneExt: true
            },
            email: {
                required: true,
                email: true,
                emailExt: true
            }
        },
        submitHandler: function (form) {
            $('.edit-plat-user').attr('disabled', true);
            var firstname = $('#edit_user_firstname').val();
            var lastname = $('#edit_user_lastname').val();
            var email = $('#edit_user_email').val();
            var mobile = $('#edit_user_mobile').intlTelInput("getNumber");
            var usertype = $('.edit_user_type:checked').val();
            var dp = $('.edit-profile-dp').attr('src');
            var is_coordinator = ($('.edit_user_is_coordinator:checked').val() === "on") ? true : false;
            payload = {
                "firstname": firstname,
                "lastname": lastname,
                "email": email,
                "mobile": mobile,
                "dp": dp,
                "usertype": usertype,
                "is_coordinator": is_coordinator
            };
            var user_id = $('#edit-user').attr('data-user');
            console.log(payload);
            $.ajax({
                url: "/edituser/" + user_id,
                type: "POST",
                data: JSON.stringify(payload),
                success: function (resp) {
                    console.log(resp);
                    $('#edit-user').modal('hide');
                    window.location.reload();
                },
                error: function (err) {
                    console.log(err);
                }
            });
        }
    });

});

// Delete User
$(document).on('click', '.delete-user', function () {
    var user_id = $(this).attr('data-user');
    $('.confirm-user-deletion').attr('data-user', user_id);
    $('#delete-user').modal('show');
});
$(document).on('click', '.confirm-user-deletion', function () {
    var user_id = $(this).attr('data-user');
    console.log("DELETING THIS USER", user_id);
    $.ajax({
        url: "/user/" + user_id,
        type: "DELETE",
        success: function (resp) {
            console.log(resp);
            window.location.reload();
        },
        error: function (err) {
            console.log(err);
        }
    });
});

// Edit User
$(document).on('click', '.edit-user', function () {
    var user_id = $(this).attr('data-user');
    $('.confirm-user-edit').attr('data-user', user_id);
    $.ajax({
        url: "/user/" + user_id,
        type: "GET",
        success: function (resp) {
            console.log(resp);
            console.log(resp.user.firstname);
            $('#edit_user_firstname').val(resp.user.firstname);
            $('#edit_user_lastname').val(resp.user.lastname);
            $('#edit_user_email').val(resp.user.email);
            $('#edit_user_mobile').intlTelInput("setNumber", resp.user.mobile);
            $('#edit-user input[value=' + resp.user.usertype + ']').prop("checked", true);
            if (resp.user.is_coordinator) {
                $('#edit_user_is_coordinator').prop("checked", true);
            } else {
                $('#edit_user_is_coordinator').prop("checked", false);
            }
            $('.edit-profile-dp').attr("src", resp.user.dp);
            $('#edit-user').attr('data-user', user_id);
            $('#edit-user').modal('show');
        },
        error: function (err) {
            console.log(err);
        }
    });
});

$(document).on('click', '.client-edit-step-prev-2', function () {
    $('#edit_parent_email-error').remove();
    $('#edit_parent_mobile-error').remove();
    $('#editclient02').modal('hide');
    $('#editclient01').modal('show');
});

$(document).on('click', '.edit-client-profile', function () {
    console.log('GET PROFILE');
    var user_id = $(this).attr('data-client');
    $.ajax({
        url: "/clientinfo/" + user_id,
        type: "GET",
        success: function (resp) {
            console.log("CLIENT PROFILE", resp);
            $('#edit_fistname').val(resp.user.firstname);
            $('#edit_lastname').val(resp.user.lastname);
            $('#edit_patientid').val(resp.user.patientid);
            $('#edit_dob').val(resp.user.dob);
            var gender = resp.user.gender;
            if (gender === "") {
                gender = "Gender"
            }
            $('#edit_gender').val(gender);
            $('#edit_address').val(resp.user.address);
            $('#edit_landline').val(resp.user.landline);
            $('#edit_mobile').intlTelInput("setNumber", resp.user.mobile);
            $('#edit_email').val(resp.user.email);
            $('#edit_skype').val(resp.user.skype);
            $('#edit_zoom').val(resp.user.zoom);
            $('#edit_parent_email').val(resp.user.parent_email);
            $('#edit_parent_mobile').intlTelInput("setNumber", resp.user.parent_mobile);
            $('#edit_placeofwork').val(resp.user.placeofwork);
            $('#edit_yearofwork').val(resp.user.yearofwork);
            $('#edit_referalsource').val(resp.user.referalsource);
            if (resp.user.esignature != "") {
                $('#edit_upload_signature').attr('data-url', resp.user.esignature);
                $('#edit_upload_signature').removeClass('btn-outline-primary').addClass('btn-secondary');
                $('#edit_upload_signature').html('<span class="fa fa-pencil"></span> &nbsp;e-signature uploaded');
                $('#edit_edit_signature').removeClass('d-none');
            }
            if (resp.user.photoid !== "") {
                $('#edit_upload_photoid').attr('data-url', resp.user.photoid);
                $('#edit_upload_photoid').removeClass('btn-outline-primary').addClass('btn-secondary');
                $('#edit_upload_photoid').html('<span class="fa fa-pencil"></span> &nbsp;photo id uploaded');
                $('#edit_edit_photoid').removeClass('d-none');
            }
            $('#editclient01').modal('show');
        },
        error: function (err) {
            console.log(err);
        }
    });
});
var subValidationEdit = function () {
    $("#edit_parent_email").rules("add", {
        required: window.edit_lessthan18,
        email: window.edit_lessthan18,
        emailExt: window.edit_lessthan18
    });
    $("#edit_parent_mobile").rules("add", {
        required: window.edit_lessthan18,
        phoneExt: window.edit_lessthan18
    });
}
window.edit_lessthan18 = true;
$(document).on('turbolinks:load', function () {
    $("#edit-client-1").validate({
        rules: {
            firstname: {
                required: true
            },
            lastname: {
                required: true
            },
            patientid: {
                required: true
            },
            dob: {
                required: true
            },
            landline: {
                number: true
            },
            mobile: {
                required: true,
                phoneExt: true
            },
            email: {
                required: true,
                email: true,
                emailExt: true
            }
        },
        submitHandler: function (form) {
            console.log('form', form);
            var user_id = $('.profile-view').attr('data-profile');
            var payload = {
                email: $('#edit_email').val(),
                mobile: $('#edit_mobile').intlTelInput("getNumber"),
                patientid: $('#edit_patientid').val(),
                user_id: user_id
            };
            var dob = moment($('#edit_dob').val(), 'DD-MM-YYYY');
            var years = moment().diff(dob, 'years');
            if (years >= 18) {
                window.edit_lessthan18 = false;
            } else {
                window.edit_lessthan18 = true;
            }
            console.log('DOB YEARS', window.edit_lessthan18, years);
            $.ajax({
                url: "/editcheck",
                type: "POST",
                data: JSON.stringify(payload),
                success: function (resp) {
                    console.log(resp);
                    subValidationEdit();
                    $('#editclient01').modal('hide');
                    $('#editclient02').modal('show');
                },
                error: function (err) {
                    // alert('Patient ID already exists');
                    console.log("FAILURE", err.responseJSON);
                    var response = err.responseJSON;
                    if (!response.patientid) {
                        $("#edit-client-1").validate().showErrors({
                            "patientid": "Patient ID Exists"
                        });
                    }
                    if (!response.mobile) {
                        $("#edit-client-1").validate().showErrors({
                            "mobile": "Mobile Number Exists"
                        });
                    }
                    if (!response.email) {
                        $("#edit-client-1").validate().showErrors({
                            "email": "Email ID Exists"
                        });
                    }
                }
            });
        }
    });
    $("#edit-client-2").validate({
        rules: {
            parent_email: {
                email: window.edit_lessthan18,
                required: window.edit_lessthan18,
                emailExt: window.edit_lessthan18
            },
            parent_mobile: {
                required: window.edit_lessthan18,
                phoneExt: window.edit_lessthan18
            }
        },
        submitHandler: function (form) {
            $('.client-edit-step-next-2').attr('disabled', true);
            var patientid = $('#edit_patientid').val();
            var firstname = $('#edit_fistname').val();
            var lastname = $('#edit_lastname').val();
            var dob = $('#edit_dob').val();
            var gender = $('#edit_gender').val();
            if (gender === "Gender") {
                gender = ""
            }
            var address = $('#edit_address').val();
            var landline = $('#edit_landline').val();
            var mobile = $('#edit_mobile').intlTelInput("getNumber");
            var email = $('#edit_email').val();
            var skype = $('#edit_skype').val();
            var zoom = $('#edit_zoom').val();
            var parent_email = $('#edit_parent_email').val();
            var parent_mobile = $('#edit_parent_mobile').intlTelInput("getNumber");
            var esignature = $('#edit_upload_signature').attr('data-url');
            var photoid = $('#edit_upload_photoid').attr('data-url');
            var placeofwork = $('#edit_placeofwork').val();
            var yearofwork = $('#edit_yearofwork').val();
            var referalsource = $('#edit_referalsource').val();
            var user_id = $('.profile-view').attr('data-profile');
            payload = {
                "patientid": patientid,
                "firstname": firstname,
                "lastname": lastname,
                "dob": dob,
                "gender": gender,
                "address": address,
                "landline": landline,
                "mobile": mobile,
                "email": email,
                "skype": skype,
                "zoom": zoom,
                "parent_email": parent_email,
                "parent_mobile": parent_mobile,
                "esignature": esignature,
                "photoid": photoid,
                "placeofwork": placeofwork,
                "yearofwork": yearofwork,
                "referalsource": referalsource
            };
            console.log(payload);
            $.ajax({
                url: "/updateclient/" + user_id,
                type: "PUT",
                data: JSON.stringify(payload),
                success: function (resp) {
                    console.log(resp);
                    $('#editclient02').modal('hide');
                    window.location.reload();
                },
                error: function (err) {
                    console.log(err);
                }
            });
        }
    });
});

// SELF EDIT
$(document).on('click', '.edit-own-profile', function () {
    // $(this).addClass('active');
    console.log('GET PROFILE');
    var user_id = $(this).attr('data-profile');
    $.ajax({
        url: "/user/" + user_id,
        type: "GET",
        success: function (resp) {
            console.log("MY OWN PROFILE", resp);
            $('#edit_self_firstname').val(resp.user.firstname);
            $('#edit_self_lastname').val(resp.user.lastname);
            $('#edit_self_mobile').intlTelInput("setNumber", resp.user.mobile);
            $('#edit_self_email').val(resp.user.email);
            $('.edit-profile-dp').attr("src", resp.user.dp);
            $('#edit-self').modal('show');
        },
        error: function (err) {
            console.log(err);
        }
    });
});

$(document).on('click', '.edit_user_type', function () {
    var selectedvalue = $(this).attr('value');
    console.log(selectedvalue);
    if (selectedvalue === "admin") {
        $('.add-coord').addClass('d-none');
        $('#select-coordinator').prop('checked', false);
        $('#edit_user_is_coordinator').prop('checked', false);
    } else {
        $('.add-coord').removeClass('d-none');
    }
});

$(document).on('click', '.new_user_type', function () {
    var selectedvalue = $(this).attr('value');
    console.log(selectedvalue);
    if (selectedvalue === "admin") {
        $('.add-coord').addClass('d-none');
        $('#select-coordinator').prop('checked', false);
        $('#new_is_coordinator').prop('checked', false);
    } else {
        $('.add-coord').removeClass('d-none');
    }
});

$(document).on('click', '.close-create-user', function () {
    $('#addUser').modal('hide');
    window.location.reload();
});

$(document).on('click', '.close-edit-user', function () {
    $('#edit-user').modal('hide');
    window.location.reload();
});