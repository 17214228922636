$(document).on('click', '.activate-intake-tab', function () {
    $('#assign-search').val('');
    $('.assign-list .list-group-item').removeClass('d-none');
    $('#assign-any .search-box span').removeClass('fa-close');
    $('#assign-any .search-box span').addClass('fa-search');
    $('.activate-clinician-tab').removeClass('active');
    $('.activate-coordinator-tab').removeClass('active');
    $('.activate-intake-tab').addClass('active');
    $('#list-clinician').addClass('d-none');
    $('#list-coordinator').addClass('d-none');
    $('#list-intakespecialist').removeClass('d-none');
    var counter = 0;
    $('#list-intakespecialist .assignable:checked').each(function () {
        counter = counter + 1;
    });
    $('#assign-any .selected-count').text(counter);
});
$(document).on('click', '.activate-clinician-tab', function () {
    $('#assign-search').val('');
    $('.assign-list .list-group-item').removeClass('d-none');
    $('#assign-any .search-box span').removeClass('fa-close');
    $('#assign-any .search-box span').addClass('fa-search');
    $('.activate-coordinator-tab').removeClass('active');
    $('.activate-intake-tab').removeClass('active');
    $('.activate-clinician-tab').addClass('active');
    $('#list-coordinator').addClass('d-none');
    $('#list-intakespecialist').addClass('d-none');
    $('#list-clinician').removeClass('d-none');
    var counter = 0;
    $('#list-clinician .assignable:checked').each(function () {
        counter = counter + 1;
    });
    $('#assign-any .selected-count').text(counter);
});
$(document).on('click', '.activate-coordinator-tab', function () {
    $('#assign-search').val('');
    $('.assign-list .list-group-item').removeClass('d-none');
    $('#assign-any .search-box span').removeClass('fa-close');
    $('#assign-any .search-box span').addClass('fa-search');
    $('.activate-intake-tab').removeClass('active');
    $('.activate-clinician-tab').removeClass('active');
    $('.activate-coordinator-tab').addClass('active');
    $('#list-clinician').addClass('d-none');
    $('#list-intakespecialist').addClass('d-none');
    $('#list-coordinator').removeClass('d-none');
    var counter = 0;
    $('#list-coordinator .assignable:checked').each(function () {
        counter = counter + 1;
    });
    $('#assign-any .selected-count').text(counter);
});
// Assign Psychiatrist
$(document).on('click', '.assign-any', function () {
    var assignable = [];
    var user_id = $('.profile-view').attr('data-profile');
    $('.assignable:checked').each(function () {
        var checked = $(this).val();
        var role = $(this).attr('data-role');
        var tmp = {
            "role": role,
            "_id": checked
        }
        console.log(tmp);
        assignable.push(tmp);
    });
    console.log("SELECTED ", assignable);
    var payload = {
        "assignable": assignable,
        "user_id": user_id
    };
    console.log(payload);
    $.ajax({
        url: "/assign_any",
        type: "POST",
        data: JSON.stringify(payload),
        success: function (resp) {
            console.log(resp);
            $('#assign-any').modal('hide');
            window.location.reload();
        },
        error: function (err) {
            console.log(err);
        }
    });
});

$(document).on('click', '.btn-assign-close', function () {
    $('#assign-any').modal('hide');
    window.location.reload();
});

$(document).on('click', '.share-client', function () {
    var assignable = [];
    var user_id = $('.profile-view').attr('data-profile');
    $('.share-with-users:checked').each(function () {
        var checked = $(this).val();
        console.log(checked);
        assignable.push(checked);
    });
    console.log("SELECTED ", assignable);
    var payload = {
        "assignable": assignable,
        "user_id": user_id
    };
    $.ajax({
        url: "/assign_any",
        type: "POST",
        data: JSON.stringify(payload),
        success: function (resp) {
            console.log(resp);
            $('#share-client').modal('hide');
        },
        error: function (err) {
            console.log(err);
        }
    });
});

// View Client
$(document).on('click', '.view-client', function () {
    var clientid = $(this).attr('data-userid');
    $.ajax({
        url: "/clientinfo/" + clientid,
        type: "GET",
        success: function (resp) {
            console.log(resp);
            $('.client-name').text(resp.user.firstname + ' ' + resp.user.lastname);
            $('.client-dob').text(resp.user.dob);
            $('.client-gender').text(resp.user.gender);
            $('.client-address').text(resp.user.address);
            $('.client-landline').text(resp.user.landline);
            $('.client-mobile').text(resp.user.mobile);
            $('.client-email').text(resp.user.email);
            $('.client-skype').text(resp.user.skype);
            $('.client-zoom').text(resp.user.zoom);
            $('.client-parent_email').text(resp.user.parent_email);
            $('.client-parent_mobile').text(resp.user.parent_mobile);
            $('.client-esignature').text(resp.user.esignature);
            $('.client-photoid').text(resp.user.photoid);
            $('.client-placeofwork').text(resp.user.placeofwork);
            $('.client-yearofwork').text(resp.user.yearofwork);
            $('.client-referalsource').text(resp.user.referalsource);

            $('#view-client-info').modal('show');
        },
        error: function (err) {
            console.log(err);
        }
    });
});

$(document).on('click', '.add-shares', function () {
    var clientid = $(this).attr('data-userid');
    $('.note-sharables').empty();
    $('.note-sharables-edit').empty();
    $('.note-sharables-edit-partial').empty();
    $('.communication-sharables').empty();
    $('.communication-sharables-edit').empty();
    var shared_with = [];
    $('.share-with-users-note:checked').each(function () {
        var checked = $(this).val();
        var name = $(this).attr('data-name');
        var payload = {
            "checked": checked,
            "name": name
        }
        console.log(payload);
        shared_with.push(payload);
    });
    console.log("SHARED WITH", shared_with);
    shared_with.forEach(function (sharable) {
        var row = '<div class="chip" data-shareid="' + sharable.checked + '"><div class="chip-content">' + sharable.name + '</div><div class="chip-close" data-id="' + sharable.checked + '"><svg class="chip-svg" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path></svg></div></div>';
        $('.note-sharables').append(row);
        $('.note-sharables-edit').append(row);
        $('.note-sharables-edit-partial').append(row);
        $('.communication-sharables').append(row);
        $('.communication-sharables-edit').append(row);
    });
    $('#add-shares').modal('hide');
});

$(document).on('click', '.chip-close', function () {
    var id = $(this).attr('data-id');
    console.log("Deletable", id);
    $('.chip[data-shareid="' + id + '"').remove();
});

$(document).on('click', '.download-pop', function () {
    var note_id = $(this).attr('data-note');
    console.log(note_id);
    $.ajax({
        url: "/notes/" + note_id,
        type: "GET",
        success: function (resp) {
            console.log(resp);
            $('#download-popup .notes-link').attr('href', "/download/" + resp.note._id);
            $('#download-popup .download-type').text(resp.note.type);
            if ((resp.note.path !== "") && (resp.note.path !== null)) {
                $('#download-popup .notes-attachment').removeClass('d-none');
                $('#download-popup .notes-attachment').attr('href', resp.note.path);
            } else {
                $('#download-popup .notes-attachment').addClass('d-none');
            }
            $('#download-popup').modal('show');
        },
        error: function (err) {
            console.log(err);
        }
    });
});

$(document).on('click', '.btn-assign', function () {
    var activetab = $(this).attr('data-tab');
    $('#assign-any .nav-link').removeClass('active');
    $('#assign-any .assign-list').addClass('d-none');
    var counter = 0;
    switch (activetab) {
        case "intakespecialist":
            $('.activate-intake-tab').addClass('active');
            $('#list-intakespecialist').removeClass('d-none');
            $('#list-intakespecialist .assignable:checked').each(function () {
                counter = counter + 1;
            });
            $('#assign-any .selected-count').text(counter);
            break;
        case "clinician":
            $('.activate-clinician-tab').addClass('active');
            $('#list-clinician').removeClass('d-none');
            $('#list-clinician .assignable:checked').each(function () {
                counter = counter + 1;
            });
            $('#assign-any .selected-count').text(counter);
            break;
        case "coordinator":
            $('.activate-coordinator-tab').addClass('active');
            $('#list-coordinator').removeClass('d-none');
            $('#list-coordinator .assignable:checked').each(function () {
                counter = counter + 1;
            });
            $('#assign-any .selected-count').text(counter);
            break;
        default:
        // code block
    }
    console.log(activetab);
    var title = $(this).attr('data-title');
    var button = $(this).attr('data-button');
    console.log(title, button);

    $('#assign-any .modal-title').text(title);
    $('#assign-any .assign-any').text(button);
    $('#assign-any').modal('show');
});

$(document).on('click', '.assignable', function () {
    console.log('assignable clicked');
    var activetab = $(this).attr('data-role');
    console.log('active tab', activetab);
    var counter = 0;
    switch (activetab) {
        case "intakespecialist":
            $('.activate-intake-tab').addClass('active');
            $('#list-intakespecialist').removeClass('d-none');
            $('#list-intakespecialist .assignable:checked').each(function () {
                counter = counter + 1;
            });
            $('#assign-any .selected-count').text(counter);
            break;
        case "clinician":
            $('.activate-clinician-tab').addClass('active');
            $('#list-clinician').removeClass('d-none');
            $('#list-clinician .assignable:checked').each(function () {
                counter = counter + 1;
            });
            $('#assign-any .selected-count').text(counter);
            break;
        case "coordinator":
            $('.activate-coordinator-tab').addClass('active');
            $('#list-coordinator').removeClass('d-none');
            $('#list-coordinator .assignable:checked').each(function () {
                counter = counter + 1;
            });
            $('#assign-any .selected-count').text(counter);
            break;
        default:
        // code block
    }
});

$(document).on('keyup', '#user_password', function () {
    var changed_pass_value = $('#user_password').val();
    var changed_conf_value = $('#user_password_confirmation').val();
    console.log('PASSWORD CHANGE', changed_pass_value, changed_conf_value);
    if ((changed_pass_value === changed_conf_value) && (changed_pass_value.length > 5)) {
        $('.update-login-password').attr('disabled', false);
        $('.login-box .nomatch').css('display', 'none');
        $('.login-box .nolength').css('display', 'none');
    } else {
        $('.update-login-password').attr('disabled', true);
        console.log("NOT MATCHING PASSWORDS");
        if (changed_pass_value === changed_conf_value) {
            $('.login-box .nomatch').css('display', 'none');
        } else {
            $('.login-box .nomatch').css('display', 'block');
        }
        if (changed_pass_value.length > 5) {
            $('.login-box .nolength').css('display', 'none');
        } else {
            $('.login-box .nolength').css('display', 'block');
        }
    }
});
$(document).on('keyup', '#user_password_confirmation', function () {
    var changed_pass_value = $('#user_password').val();
    var changed_conf_value = $('#user_password_confirmation').val();
    console.log('PASSWORD CHANGE', changed_pass_value, changed_conf_value);
    if ((changed_pass_value === changed_conf_value) && (changed_pass_value.length > 5)) {
        $('.update-login-password').attr('disabled', false);
        $('.login-box .nomatch').css('display', 'none');
        $('.login-box .nolength').css('display', 'none');
    } else {
        $('.update-login-password').attr('disabled', true);
        console.log("NOT MATCHING PASSWORDS");
        if (changed_pass_value === changed_conf_value) {
            $('.login-box .nomatch').css('display', 'none');
        } else {
            $('.login-box .nomatch').css('display', 'block');
        }
        if (changed_pass_value.length > 5) {
            $('.login-box .nolength').css('display', 'none');
        } else {
            $('.login-box .nolength').css('display', 'block');
        }
    }
});